
export const environment = {
  production: true,
  appName: 'LeadGuru.pl',
  globalTitle: ' - LeadGuru.pl',
  defaultLang: 'pl',
  appVersion: 'v723demo2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  isComplaintsEnabled: false,
  apiUrl: 'https://mpadmin.leadit.pl/fe',
  backendUrl: 'https://mpadmin.leadit.pl/fe',
  frontendUrl: 'https://app.leadguru.pl/',
  basicAuth: 'leadit:M2QnX6',
};

export const mapEnvironment = {
  apiKey: '',
};

<div class="form" *ngIf="!loading; else spinner">
  <div class="alert alert-custom alert-light-info mb-10" role="alert">
    <span *ngIf="!config?.readonly" [innerHTML]="'GLOBAL_PRODUCTS.PREAMBLE' | translate"></span>
    <span *ngIf="config?.readonly" [innerHTML]="'GLOBAL_PRODUCTS.IC_CHANGE_BLOCKED' | translate"></span>
  </div>

  <span *ngFor="let product of config?.products">
    <div class="form-group row" *ngIf="product.companies != null && product.companies?.length > 0">
      <div class="ml-4 font-weight-bold text-dark row">
        <h3> {{'GLOBAL_PRODUCTS.'+product.name | translate}} </h3>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-4 col-sm-12" *ngFor="let company of sortArrayByProperty(product.companies, 'short_name')">
        <label class="col-form-label d-flex align-items-start">
          <mat-slide-toggle class="mr-5" [(ngModel)]="company.selected" [color]="'warn'" (change)="configUpdate(product)" [disabled]="config.readonly"></mat-slide-toggle>
          <div><p class="mb-0">{{company.short_name}}</p><p class="text-gray text-small">{{company.name}}</p></div>
        </label>
      </div>
      <div class="p-5" *ngIf="categorySelection(product.id).length == 1">
        <p [innerHTML]="'GLOBAL_PRODUCTS.EXCLUSIVE_AGENT' | translate:{ company: getSelectedCompanyForProduct(product).long_name, category: 'GLOBAL_PRODUCTS.'+product.name | translate }"></p>
        <label class="mt-5">
          <input type="checkbox" class="mr-2 hue" [(ngModel)]="product.agreement" (change)="configUpdate()" [disabled]="config.readonly" />
          <span class="text-secondary" [innerHTML]="'GLOBAL_PRODUCTS.EXCLUSIVE_AGREEMENT' | translate:{ company: getSelectedCompanyForProduct(product).long_name, category: 'GLOBAL_PRODUCTS.'+product.name | translate }"> </span>
        </label>
      </div>
      <div class="p-5" *ngIf="categorySelection(product.id).length > 1">
        <p [innerHTML]="'GLOBAL_PRODUCTS.MULTI_AGENT' | translate:{ category: 'GLOBAL_PRODUCTS.'+product.name | translate }"></p>
        <label class="mt-5">
          <input type="checkbox" class="mr-2 hue" [(ngModel)]="product.agreement" (change)="configUpdate()" [disabled]="config.readonly" />
          <span class="text-secondary" [innerHTML]="'GLOBAL_PRODUCTS.MULTI_AGREEMENT' | translate:{ category: 'GLOBAL_PRODUCTS.'+product.name | translate }"> </span>
        </label>
      </div>

      <div class="pl-5 text-danger" *ngIf="!product.agreement && categorySelection(product.id).length > 0" [id]="product.id+productAgreement">
        {{ 'GLOBAL_PRODUCTS.AGREEMENT_REQUIRED' | translate }}
      </div>

    </div>
  </span>

  <div [id]="requiredWarning" class="alert alert-custom alert-light-info" role="alert" style="display: block;">
    <p class="text-danger mb-0" *ngIf="!isSelected">
      {{ 'GLOBAL_PRODUCTS.REQUIRED' | translate }}
    </p>
    <p class="mb-0">
      {{ 'GLOBAL_PRODUCTS.MISSING' | translate }}
    </p>
  </div>

</div>

<ng-template #spinner>
  <div class="text-center w-100 p-10">
    <span class="spinner spinner-primary"></span>
  </div>
</ng-template>
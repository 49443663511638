<div *ngIf="showComplaints">
    <ng-container *ngIf="result != null; else clean">
        <span [ngClass]="complaintButton ? 'btn btn-info text-white ml-2 font-size-sm' : 'text-danger'">
            {{ ( result ? 'LEADS.COMPLAINT.HAS_BEEN_SENT' : 'LEADS.COMPLAINT.TRY_AGAIN_LATER' ) | translate }}
        </span>
    </ng-container>
    <ng-template #clean>
        <span [ngClass]="complaintButton ? 'btn btn-info cursor-pointer text-white ml-2 font-size-sm' : 'cursor-pointer text-danger'" *ngIf="lead.bought && !lead.refunded && lead.refundable && !lead?.complaint?.id" (click)="complaint()">
            {{ 'LEADS.COMPLAINT.SUBMIT_COMPLAINT' | translate }}
        </span>
        <span [ngClass]="complaintButton ? 'btn btn-info cursor-pointer text-white ml-2 font-size-sm' : 'd-inline-block text-danger cursor-pointer mt-1'" [ngStyle]="{'line-height': !complaintButton ? '14px' : null}" *ngIf="lead?.complaint && lead?.complaint?.id" (click)="complaint()">
            {{ 'LEADS.COMPLAINT.STATUS' | translate }}: {{'LEADS.COMPLAINT.STATUSES.'+lead.complaint.status | translate}}
        </span>
    </ng-template>
</div>
import { Inject, Injectable } from '@angular/core';
import { IAuthService } from './auth-service-interface';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { ForgotPasswordDto } from '../_dto/forgot-password-dto';
import { RegisterResponse } from '../_responses/register.response';
import { LoginResponse } from '../_responses/login.response';
import { ResetPasswordDto } from '../_dto/reset-password-dto';
import { ResetPasswordResponse } from '../_responses/reset-password.response';
import { LoginDto } from '../_dto/login-dto';
import { RegisterDto } from '../_dto/register-dto';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../../../_metronic/shared/models/api-response';
import { ForgotPasswordResponse } from '../_responses/forgot-password.response';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { MessageService } from '../../../_metronic/shared/services/message.service';
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { Refresh } from '../_models/refresh.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFlag } from '..';

@Injectable({
  providedIn: 'root'
})
export class FakeAuthService extends IAuthService {

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private message: MessageService,
    private modale: NgbModal,
    router: Router,
  ) {
    super(router, config, modale);
  }

  online(): Observable<boolean>{
    return of(true);
  }

  changeLang(lang: string): Promise<boolean> {
    this.isLoadingSubject.next(true);

    return new Promise(resolve => {
      resolve(true);
    });
  }

  forgotPassword(data: ForgotPasswordDto): Observable<ApiResponse<ForgotPasswordResponse>> {
    this.isLoadingSubject.next(true);

    return of({
      status: 0,
      message: 'Jeżeli podałeś prawidłowy adres e-mail przypisany do Twojego konta, za chwilę otrzymasz wiadomość z linkiem umożliwiającym ustawienie nowego hasła.',
      data: null,
    }).pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  login(credentials: LoginDto): Observable<ApiResponse<LoginResponse>> {
    const { email, password } = credentials;

    const notFound: ApiResponse<LoginResponse> = {
      status: 1,
      message: 'Nieprawidłowy adres e-mail lub hasło.',
      data: undefined
    };

    if (!email || !password || email !== 'test@example.com') {
      this.message.error(notFound.message);

      return of(notFound).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      );
    }

    const auth = this.getAuthModel();

    const response: ApiResponse<LoginResponse> = {
      status: 0,
      message: '',
      data: auth
    };

    return of(response)
      .pipe(
        map((auth) => {
          this.setAuthToLocalStorage(auth.data);
          return auth.data;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  refreshToken(token: string): Observable<ApiResponse<LoginResponse>> {
    const notFound: ApiResponse<LoginResponse> = {
      status: 1,
      message: 'Brak autoryzacji.',
      data: undefined
    };

    if (!token) {
      this.message.error(notFound.message);

      return of(notFound).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      );
    }

    const auth = this.getAuthModel();

    const response: ApiResponse<LoginResponse> = {
      status: 0,
      message: '',
      data: auth
    };

    return of(response)
      .pipe(
        map((auth) => {
          this.setAuthToLocalStorage(auth.data);
          return auth.data;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  getAgreements(): Observable<ApiResponse<AgreementDto[]>> {
    return of({
      status: 0,
      message: 'Pobrano listę zgód.',
      data: [
        new AgreementDto(1,'Akceptuję postanowienia <a href="https://www.leadguru.pl/polityka-prywatnosci/" class="link" target="_blank">Polityki prywatności</a>.',true,false),
        new AgreementDto(1,`Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od LeadGuru sp. z o.o. z wykorzystaniem telekomunikacyjnych urządzeń końcowych (m.in. telefon) oraz środków komunikacji elektronicznej (m.in. SMS, e-mail). <small>Ta zgoda jest nam potrzebna, abyśmy mogli skontaktować się np. w celu pomocy w rejestracji, informowaniu o nowych leadach i/lub przesyłaniu wiadomości o nowych ofertach LeadGuru. W każdej chwili można cofnąć wyrażoną zgodę. Przysługuje Ci też m.in. prawo dostępu do danych, ich usunięcia i prawo do przenoszenia.</small>`,true,false)]
    });
  }

  setFlag(flag: UserFlag): Observable<ApiResponse<any>>{
    return of({
      status: 0,
      message: 'Ok',
      data: null
    });
  }

  registration(userData: RegisterDto): Observable<ApiResponse<RegisterResponse>> {
    if (userData.email !== 'test@example.com') {
      const message = 'Wystąpił błąd podczas tworzenia konta. Prosimy spróbować ponownie.';
      this.message.error(message);

      return of({
        status: 1,
        message
      }).pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    return of({
      status: 0,
      message: 'Twoje konto zostało utworzone.',
      data: this.getAuthModel()
    }).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(userData)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPassword(data: ResetPasswordDto): Observable<ApiResponse<ResetPasswordResponse>> {
    if (data.email !== 'test@example.com') {
      const message = 'Wystąpił błąd podczas resetowania hasła. Prosimy spróbować ponownie.';
      this.message.error(message);

      return of({
        status: 1,
        message
      }).pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    return of({
      status: 0,
      message: 'Nowe hasło zostało zapisane. Możesz się teraz zalogować.',
      data: null
    });
  }

  resendMail(email: any) : Observable<ApiResponse> {
    return of({
      status: 0,
      message: 'Email weryfikacyjny został ponownie wysłany'
    });
  }

  getUser(): Observable<ApiResponse<User>> {
    const user = this.getAuthModel().user;
    user.firstName = Math.random().toString(36).substr(2, 5);

    return of({
      status: 0,
      message: 'Pobrano użytkownika.',
      data: user
    })
      .pipe(
        map((response) => {
          this.setUserToLocalStorage(response.data);
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  snapshot(): Observable<ApiResponse<Refresh>> {
    const user = new Refresh;
    user.points = 10000;
    user.locale = 'pl';
    user.active = 'ACTIVATED';

    return of({
      status: 0,
      message: 'Pobrano użytkownika.',
      data: user
    })
      .pipe(
        map((response) => {
          this.updateLocalStorage(response.data);
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  private getAuthModel(): AuthModel {
    const expirationDate = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);

    const auth = new AuthModel();
    auth.accessToken = 'at-8f3ae836da744329a6f93bf20594b5cc';
    auth.refreshToken = 'rt-8f3ae836da744329a6f93bf20594b5cc';
    auth.expiresAt = expirationDate.toISOString();
    auth.user = {
      id: 1,
      firstName: 'John',
      lastName: 'Doe',
      email: 'admin@demo.com',
      phone: '456669067890',
      avatar: './assets/media/users/default.jpg',
      points: 1350,
      address: {
        street: 'Kazimierza Pułaskiego',
        buildingNumber: '11',
        apartmentNumber: '',
        postalCode: '63-400',
        city: 'Ostrów Wielkopolski',
      },
      invoiceInformation: {
        companyName: 'Test Company',
        tin: '66222444555',
        address: {
          street: 'Kazimierza Pułaskiego',
          buildingNumber: '11',
          apartmentNumber: '',
          postalCode: '63-400',
          city: 'Ostrów Wielkopolski',
        },
      },
      notificationsSettings: {
        sms: {
          new_lead: true,
          marketing: false,
        },
        email:{
          new_lead: false,
          marketing: true,
        }
      }
    } as User;

    return auth;
  }
}

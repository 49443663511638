
    <div
      #wizard
      class="wizard wizard-3"
      id="kt_wizard_v3"
      data-wizard-state="step-first"
    >
      <div class="kt-grid__item">
        <!--begin: Form Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <a
              class="wizard-step"
              href="javascript:;"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <div class="wizard-title"><span>1</span> {{'PRODUCTS.BASIC.STEPS.INVOICE_DETAILS' | translate}}</div>
                <div class="wizard-bar"></div>
              </div>
            </a>
            <a class="wizard-step" href="javascript:;" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-title"><span>2</span> {{'PRODUCTS.BASIC.STEPS.CONTACT_DETAILS' | translate}}</div>
                <div class="wizard-bar"></div>
              </div>
            </a>
            <a class="wizard-step" href="javascript:;" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-title"><span>3</span> {{'PRODUCTS.BASIC.STEPS.ID_NUMBERS' | translate}}</div>
                <div class="wizard-bar"></div>
              </div>
            </a>
            <!-- <a class="wizard-step" href="javascript:;" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-title"><span>4</span> Delivery Address</div>
                <div class="wizard-bar"></div>
              </div>
            </a>
            <a class="wizard-step" href="javascript:;" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-title"><span>5</span> Review and Submit</div>
                <div class="wizard-bar"></div>
              </div>
            </a> -->
          </div>
        </div>
        <!--end: Form Wizard Nav -->
      </div>
      <div>
        <div>
          <!--begin: Form Wizard Form-->
          <div class="text-center" *ngIf="!steps">
            Loading...
          </div>
          <form class="form" id="kt_form">
            <!--begin: Form Wizard Step 1-->
            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current" *ngIf="steps">
              <h4 class="mt-10 ml-10 font-weight-bold text-dark">
                {{'PRODUCTS.BASIC.INVOICE_DETAILS.TITLE' | translate}}
              </h4>
              <div class="card-body" [formGroup]="steps[1]">


                <div class="form-group row">
                  <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.COMPANY_NAME' | translate}}</label>
                  <div class="col-9">
                    <input class="form-control form-control-lg form-control-solid"
                           type="text"
                           formControlName="companyName"
                           [class.is-invalid]="isControlInvalid(1,'companyName')"
                           [class.is-valid]="isControlValid(1,'companyName')"
                    />
                  </div>
                </div>

                <ng-container formGroupName="address">
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.STREET' | translate}}</label>
                    <div class="col-9">
                      <input class="form-control form-control-lg form-control-solid"
                             type="text"
                             formControlName="street"
                             [class.is-invalid]="isControlInvalid(1,'street','address')"
                             [class.is-valid]="isControlValid(1,'street','address')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.NUMBER' | translate}}</label>
                    <div class="col-9">
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <input class="form-control form-control-lg form-control-solid"
                                 type="text"
                                 maxlength="5"
                                 formControlName="buildingNumber"
                                 [class.is-invalid]="isControlInvalid(1,'buildingNumber','address')"
                                 [class.is-valid]="isControlValid(1,'buildingNumber','address')"
                          />
                        </div>
                        <div class="px-2 d-flex align-items-center">/</div>
                        <div class="flex-grow-1">
                          <input class="form-control form-control-lg form-control-solid"
                                 type="text"
                                 maxlength="5"
                                 formControlName="apartmentNumber"
                                 [class.is-invalid]="isControlInvalid(1,'apartmentNumber','address')"
                                 [class.is-valid]="isControlValid(1,'apartmentNumber','address')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.POSTAL_CODE' | translate}}</label>
                    <div class="col-9">
                      <input type="text"
                             class="form-control form-control-lg form-control-solid"
                             formControlName="postalCode"
                             pattern="\d{2}-\d{3}"
                             inputmode="numeric"
                             mask="99-999" [dropSpecialCharacters]="false"
                             [class.is-invalid]="isControlInvalid(1,'postalCode','address')"
                             [class.is-valid]="isControlValid(1,'postalCode','address')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.CITY' | translate}}</label>
                    <div class="col-9">
                      <input type="text"
                             class="form-control form-control-lg form-control-solid"
                             formControlName="city"
                             [class.is-invalid]="isControlInvalid(1,'city','address')"
                             [class.is-valid]="isControlValid(1,'city','address')"
                      />
                    </div>
                  </div>
                </ng-container>                
        
                <div class="form-group row">
                  <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.INVOICE_DETAILS.TIN' | translate}}</label>
                  <div class="col-9">
                    <input class="form-control form-control-lg form-control-solid"
                          type="text"
                          pattern="\d*" 
                          maxlength="10"
                          formControlName="tin"
                          inputmode="numeric"
                          [class.is-invalid]="isControlInvalid(1,'tin')"
                          [class.is-valid]="isControlValid(1,'tin')"
                    />
                  </div>
                </div>


              </div>

            </div>
            <!--end: Form Wizard Step 1-->

            <!--begin: Form Wizard Step 2-->
            <div class="pb-5" data-wizard-type="step-content" *ngIf="steps">
              <h4 class="mt-10 ml-10 font-weight-bold text-dark">
                {{'PRODUCTS.BASIC.CONTACT_DETAILS.TITLE' | translate}}
              </h4>

              <div class="card-body" [formGroup]="steps[2]">

                <div class="form-group row">
                  <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.CONTACT_DETAILS.USE_INVOICE_DETAILS' | translate}}</label>
                  <div class="col-9">
                    <mat-slide-toggle formControlName="useInvoiceAsContact"></mat-slide-toggle>
                  </div>
                </div>

                <ng-container formGroupName="contactAddress" *ngIf="!steps[2].controls['useInvoiceAsContact'].value">
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.CONTACT_DETAILS.STREET' | translate}}</label>
                    <div class="col-9">
                      <input class="form-control form-control-lg form-control-solid"
                             type="text"
                             formControlName="street"
                             [class.is-invalid]="isControlInvalid(2,'street','contactAddress')"
                             [class.is-valid]="isControlValid(2,'street','contactAddress')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.CONTACT_DETAILS.NUMBER' | translate}}</label>
                    <div class="col-9">
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <input class="form-control form-control-lg form-control-solid"
                                 type="text"
                                 maxlength="5"
                                 formControlName="buildingNumber"
                                 [class.is-invalid]="isControlInvalid(2,'buildingNumber','contactAddress')"
                                 [class.is-valid]="isControlValid(2,'buildingNumber','contactAddress')"
                          />
                        </div>
                        <div class="px-2 d-flex align-items-center">/</div>
                        <div class="flex-grow-1">
                          <input class="form-control form-control-lg form-control-solid"
                                 type="text"
                                 maxlength="5"
                                 formControlName="apartmentNumber"
                                 [class.is-invalid]="isControlInvalid(2,'apartmentNumber','contactAddress')"
                                 [class.is-valid]="isControlValid(2,'apartmentNumber','contactAddress')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.CONTACT_DETAILS.POSTAL_CODE' | translate}}</label>
                    <div class="col-9">
                      <input type="text"
                             class="form-control form-control-lg form-control-solid"
                             formControlName="postalCode"
                             pattern="\d{2}-\d{3}"
                             inputmode="numeric"
                             mask="99-999" [dropSpecialCharacters]="false"
                             [class.is-invalid]="isControlInvalid(2,'postalCode','contactAddress')"
                             [class.is-valid]="isControlValid(2,'postalCode','contactAddress')"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.CONTACT_DETAILS.CITY' | translate}}</label>
                    <div class="col-9">
                      <input type="text"
                             class="form-control form-control-lg form-control-solid"
                             formControlName="city"
                             [class.is-invalid]="isControlInvalid(2,'city','contactAddress')"
                             [class.is-valid]="isControlValid(2,'city','contactAddress')"
                      />
                    </div>
                  </div>
                </ng-container>                

              </div>
            </div>
            <!--end: Form Wizard Step 2-->

            <!--begin: Form Wizard Step 3-->
            <div class="pb-5" data-wizard-type="step-content" *ngIf="steps">
              <h4 class="mt-10 ml-10 font-weight-bold text-dark">
                {{'PRODUCTS.BASIC.ID_NUMBERS.TITLE' | translate}}
              </h4>

              <div class="card-body" [formGroup]="steps[3]">

                <div class="form-group row">
                  <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.ID_NUMBERS.AGENT_ID' | translate}}</label>
                  <div class="col-9">
                    <input class="form-control form-control-lg form-control-solid"
                          type="text"
                          formControlName="agentId"
                          [class.is-invalid]="isControlInvalid(3,'agentId')"
                          [class.is-valid]="isControlValid(3,'agentId')"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 col-form-label">{{'PRODUCTS.BASIC.ID_NUMBERS.WORKER_ID' | translate}}</label>
                  <div class="col-9">
                    <input class="form-control form-control-lg form-control-solid"
                          type="text"
                          formControlName="workerId"
                          [class.is-invalid]="isControlInvalid(3,'workerId')"
                          [class.is-valid]="isControlValid(3,'workerId')"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="form-group w-100">
                    <p><small class="text-muted"><span class="text-danger">*</span> {{ 'PRODUCTS.AGREEMENTS.REQUIRED_AGREEMENTS' | translate }}</small></p>
                    <label class="mb-0"><input type="checkbox" class="hue mr-2" [(ngModel)]="allChecked" (change)="checkAll()" [ngModelOptions]="{standalone: true}"/><span class="agreements">{{ 'PRODUCTS.AGREEMENTS.CHECK_ALL' | translate }}</span></label>
                    <hr>
                  </div>
                  <div class="form-group w-100" *ngFor="let agreement of agreements; index as i;">
                    <label class="mb-0">
                      <input type="checkbox" [formControlName]="'agreement'+agreement.id" [name]="'agreement'+agreement.id" class="mr-2 hue"/>
                      <span *ngIf="agreement.required" class="text-danger">* </span>
                      <span *ngIf="!show[i]"><span [innerHTML]="agreement.content | slice:0:275"></span><span *ngIf="agreement.content.length > 275">...</span></span>
                      <span *ngIf="show[i]" [innerHTML]="agreement.content"></span>
                    </label>
                    <a *ngIf="agreement.content.length > 275" class="link" href="javascript:void(0)" (click)="show[i] = !show[i]">{{ show[i] ? ('PRODUCTS.AGREEMENTS.SHOW_LESS' | translate) : ('PRODUCTS.AGREEMENTS.SHOW_MORE' | translate) }}</a>
              
                    <ng-container *ngIf="steps[3].controls['agreement'+agreement.id].errors && steps[3].controls['agreement'+agreement.id].touched">
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block text-danger">
                          {{ 'PRODUCTS.AGREEMENTS.REQUIRED' | translate }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>

            </div>
            <!--end: Form Wizard Step 3-->

            <!--begin: Form Wizard Step 4-->
            <!-- <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Setup Your Delivery Location
              </h4>

            </div> -->
            <!--end: Form Wizard Step 4-->

            <!--begin: Form Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <div
                  class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-prev"
                >
                {{'PRODUCTS.PREV_STEP' | translate}}
                </div>
              </div>
              <div>
                <div
                  (click)="onSubmit()"
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                {{'PRODUCTS.SUBMIT' | translate}}
                </div>
                <div
                  class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-next"
                >
                {{'PRODUCTS.NEXT_STEP' | translate}}
                </div>
              </div>
            </div>
            <!--end: Form Actions -->
          </form>
          <!--end: Form Wizard Form-->
        </div>
      </div>
    </div>
    <!--end: Wizard -->


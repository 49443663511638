import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Lead } from "../../_dto/lead-dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import KTWizard from '../../../../../assets/js/components/wizard';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, APP_CONFIG } from 'src/app/app-config';
import { ILeadsService } from '../../_services/leads-service-interface';
import { LeadsService } from '../../_services/leads.service';
import { FakeLeadsService } from '../../_services/fake-leads.service';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';

@Component({
  selector: 'app-lead-complaint',
  templateUrl: './lead-complaint.component.html',
  styleUrls: ['./lead-complaint.component.scss']
})
export class LeadComplaintComponent implements OnInit, AfterViewInit {

  @Input() lead: Lead;
  @Input() leadService: ILeadsService;

  private isLoadingSubject = new BehaviorSubject(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  @ViewChild('wizard', { static: true }) el: ElementRef;
  wizard: any;

  public steps;

  public reasons = [
    "NO_CONTACT_WITH_CLIENT",
    "CLIENT_NOT_INTERESTED",
    "DEAL_IMPOSSIBLE",
    "ALLREADY_HAS_DEAL_WITH_COMPANY",
    "INVALID_DATA_IN_LEAD",
    "CONTACT_REFUSAL",
    "OTHER"
  ];

  constructor(
    protected modal: NgbActiveModal,
    protected message: MessageService,
    protected translate: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) { 

  }

  ngOnInit(): void {
    this.prepareGroup();
  }

  ngAfterViewInit() {
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });
  }

  validateStep(step){
    this.steps[step].markAllAsTouched();
    this.steps[step].markAsDirty();
    for (const key in this.steps[step].controls) {
      const formElement = this.steps[step].get(key);
      formElement.updateValueAndValidity();
    }
    if(this.steps[step].invalid){
      this.changeDetectorRef.detectChanges();
      return false;
    }
    return true;
  }
  
  nextStep(){
    if(!this.steps){
      this.wizard.stop();
      return;
    }
    if(!this.validateStep(this.wizard.currentStep)){
      this.wizard.stop();
      return;
    }
    this.changeDetectorRef.detectChanges();
  }

  prevStep(){
    if(!this.steps){
      this.wizard.stop();
      return;
    }
  }

  onBack() {
    this.modal.close();
  }

  onSubmit() {
    if(!this.validateStep(this.steps.length-1)){
      return;
    }
    let output = {};
    this.steps.forEach(element => {
      if(!element){ return; }
      Object.assign(output, element.value);
    });
    const content = this.translate.instant('LEADS.COMPLAINT.agreement_value');
    output['agreement_content'] = content;
    this.leadService.finaliseComplaint(this.lead.id, output).subscribe(
      ({message, status}) => {
        if(status == 0){
          this.message.success(message);
          this.modal.close(true);
        }else{
          this.message.error(message);
          this.modal.close(false);
        }
    });
  }

  prepareGroup(){
    var steps = [];

    steps[1] = new FormGroup({
      ics: new FormArray( [], this.lead.exclusiveLead ? null :Validators.required ),
    });

    steps[2] = new FormGroup({
      reason: new FormControl( null , Validators.required ),
      desc: new FormControl( null , Validators.required )
    });

    steps[3] = new FormGroup({
      agreement: new FormControl( null , Validators.requiredTrue )
    });

    this.steps = steps;
  }

  onCheckboxChange(event: any) {
    const selected = (this.steps[1].controls['ics'] as FormArray);
    if (event.target.checked) {
      selected.push(new FormControl(event.target.value));
    } else {
      const index = selected.controls
      .findIndex(x => x.value === event.target.value);
      selected.removeAt(index);
    }
  }

  selectedIcs(){
    let output = '';
    this.lead.bought_for.forEach(element => {
      if(this.steps[1].controls['ics'].value.includes(''+element.id)){
        output += element.short_name;
        output += ', ';
      }
    });
    output = output.slice(0, -2);
    return output;
  }

  checkControl(isValid: boolean, step: number, controlName: string = null, controlParent: string = null): boolean {
    var base:any = this.steps[step];
    if(!controlName){
      return (isValid ? base.valid : base.errors) && (base.dirty || base.touched);
    }
    if(controlParent){
      var base:any = base.controls[controlParent];
    }
    const control = base.controls[controlName];
    return (isValid ? control.valid : control.invalid ) && (control.dirty || control.touched);
  }

}

import { Component } from '@angular/core';
import { Lead } from '../../_dto/lead-dto';
import { BaseDisplayComponent } from '../custom-base/base.component';

@Component({
  selector: 'app-details-display',
  template: `
  <div style="line-height: 14px; display:inline-block;">
    <strong *ngIf="value?.refunded">{{ 'LEADS.LEAD.REFUNDED' | translate }}</strong>
    <strong class="text-primary" *ngIf="!value?.refunded">{{value?.first_name}} {{value?.last_name}}</strong>
    <br>
    <small class="text-primary" *ngIf="!value?.refunded">{{value?.phone | phoneNumber}}</small>
  </div>
  `
})
export class DetailsDisplayComponent extends BaseDisplayComponent<Lead> {

  existsRefund(){
    var output = false;
    if(!this.value || !this.value?.bought_for){
      return output;
    }
    this.value.bought_for.forEach((company) => {
      if(company.refund != 0 && company.refund != null){
        output = true;
      }
    });
    return output;
  }

}

import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { EnumItem } from "../../../../_metronic/shared/models/enum-item";
import { ILeadsService } from "../../_services/leads-service-interface";
import { Lead } from "../../_dto/lead-dto";
import { LeadComplaintComponent } from '../lead-complaint/lead-complaint.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-complaint-status',
  templateUrl: './complaint-status.component.html',
  styleUrls: ['./complaint-status.component.scss']
})
export class ComplaintStatusComponent implements OnInit {

  @Input() lead: Lead;
  @Input() complaintButton: boolean = false;

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  @Output() isLoading = this.isLoadingSubject.asObservable();

  options$ = new BehaviorSubject<EnumItem[]>([]);

  public result = null;
  public showComplaints = environment.isComplaintsEnabled;

  constructor(
    private leadService: ILeadsService,
    private modal: NgbModal,
    protected changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.isLoadingSubject.next(true);
    // this.isLoadingSubject.next(false);
  }

  complaint() {
    const modalRef = this.modal.open(LeadComplaintComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.lead = this.lead;
    modalRef.componentInstance.leadService = this.leadService;
    modalRef.result.then((result) => {
      this.result = result;
      this.changeDetectorRef.detectChanges();
    }).catch(err => {})
  }

}

import { Inject, Injectable } from '@angular/core';
import { IAuthService, UserFlag } from "./auth-service-interface";
import { RegisterResponse } from "../_responses/register.response";
import { LoginResponse } from "../_responses/login.response";
import { ResetPasswordDto } from "../_dto/reset-password-dto";
import { ForgotPasswordDto } from "../_dto/forgot-password-dto";
import { ResetPasswordResponse } from "../_responses/reset-password.response";
import { LoginDto } from "../_dto/login-dto";
import { RegisterDto } from "../_dto/register-dto";
import { Observable, of } from "rxjs";
import { ApiResponse } from "../../../_metronic/shared/models/api-response";
import { ForgotPasswordResponse } from "../_responses/forgot-password.response";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { APP_CONFIG, AppConfig } from "../../../app-config";
import { Router } from "@angular/router";
import { catchError, finalize, map, switchMap } from "rxjs/operators";
import { User } from "../_models/user.model";
import { AgreementDto } from 'src/app/_dto/agreement-dto';
import { Refresh } from '../_models/refresh.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends IAuthService {

  private readonly url: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private modale: NgbModal,
    router: Router,
  ) {
    super(router, config, modale);
    this.url = `${this.config.apiUrl}/auth`;
  }

  online(): Observable<boolean> {
    return this.http.get(`${this.url}/online`)
      .pipe(
        map((response) => true),
        catchError((err) => of(false)),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  changeLang(lang: string): Promise<boolean> {
    this.isLoadingSubject.next(true);

    return new Promise(resolve => {
      this.http.post<ApiResponse>(`${this.url}/lang`, {language: lang})
      .subscribe(data => resolve(true));
    });
  }

  setFlag(flag: UserFlag): Observable<ApiResponse<any>>{
    return this.http.post<ApiResponse>(`${this.url}/flag`,{flag})
  }

  forgotPassword(data: ForgotPasswordDto): Observable<ApiResponse<ForgotPasswordResponse>> {
    this.isLoadingSubject.next(true);

    return this.http.post<ApiResponse<ForgotPasswordResponse>>(`${this.url}/forgot-password`, data)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resendMail(email: any) :  Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.url}/email/resend?email=${email}`);
  }

  login(credentials: LoginDto): Observable<ApiResponse<LoginResponse>> {
    this.isLoadingSubject.next(true);

    return this.http.post<ApiResponse<LoginResponse>>(`${this.url}/login`, credentials)
      .pipe(
        map((auth) => {
          this.setAuthToLocalStorage(auth.data);
          return auth.data;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  refreshToken(token: string): Observable<ApiResponse<LoginResponse>> {
    this.isLoadingSubject.next(true);

    return this.http.post<ApiResponse<LoginResponse>>(`${this.url}/refresh`, { token })
      .pipe(
        map((auth) => {
          this.setAuthToLocalStorage(auth.data);
          return auth.data;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          this.logout();
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  getAgreements(): Observable<ApiResponse<AgreementDto[]>> {
    this.isLoadingSubject.next(true);

    return this.http.get<ApiResponse<AgreementDto[]>>(`${this.url}/agreements`)
      .pipe(
        map((response) => {
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  registration(userData: RegisterDto): Observable<ApiResponse<RegisterResponse>> {
    this.isLoadingSubject.next(true);

    return this.http.post<ApiResponse<RegisterResponse>>(`${this.url}/register`, userData)
      .pipe(
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(data: ResetPasswordDto): Observable<ApiResponse<ResetPasswordResponse>> {
    this.isLoadingSubject.next(true);

    return this.http.post<ApiResponse<ResetPasswordResponse>>(`${this.url}/reset-password`, data)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getUser(): Observable<ApiResponse<User>> {
    this.isLoadingSubject.next(true);

    return this.http.get<ApiResponse<User>>(`${this.url}/user`)
      .pipe(
        map((response) => {
          this.setUserToLocalStorage(response.data);
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  snapshot(): Observable<ApiResponse<Refresh>> {
    if(!this.getAuthFromLocalStorage()){
      return of(new ApiResponse());
    }

    this.isLoadingSubject.next(true);

    return this.http.get<ApiResponse<Refresh>>(`${this.url}/snapshot`)
      .pipe(
        map((response) => {
          this.updateLocalStorage(response.data);
          this.confirmAgreement(response.data.agreementToConfirm, response.data.agreementDate);
          return response;
        }),
        catchError((err) => of(undefined)),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

}

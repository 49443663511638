<div class="card border-0">

  <div class="card-header p-5 d-flex justify-content-between align-items-center">
    <h4 class="text-primary mb-0">{{ 'LEADS.COMPLAINT.TITLE' | translate }}</h4>

    <div class="card-toolbar">
        <button class="btn btn-xs btn-default" (click)="onBack()">{{ 'GLOBAL.CLOSE' | translate }}</button>
    </div>
  </div>

    <div #wizard class="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" [hidden]="lead?.complaint?.id">
    <div class="kt-grid__item">
      <!--begin: Form Wizard Nav -->
      <div class="wizard-nav border-bottom mb-1 mb-lg-5 mx-0 mx-md-8">
        <div class="wizard-steps py-8 py-lg-3">
          <a class="wizard-step" href="javascript:;" data-wizard-type="step" data-wizard-state="current" *ngIf="!lead.exclusiveLead">
            <div class="wizard-label pl-0">
              <div class="wizard-title">
                <span>1</span>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_1' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step" [attr.data-wizard-state]="lead.exclusiveLead ? 'current' : null">
            <div class="wizard-label">
              <div class="wizard-title">
                <span *ngIf="!lead.exclusiveLead; else secondToFirst">2</span>
                <ng-template #secondToFirst><span>1</span></ng-template>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_2' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
          <a class="wizard-step" href="javascript:;" data-wizard-type="step">
            <div class="wizard-label">
              <div class="wizard-title">
                <span *ngIf="!lead.exclusiveLead; else thirdToSecond">3</span>
                <ng-template #thirdToSecond><span>2</span></ng-template>
                <div class="d-inline-block">{{'LEADS.COMPLAINT.WIZARD_STEPS.STEP_3' | translate}}</div>
              </div>
              <div class="wizard-bar"></div>
            </div>
          </a>
        </div>
      </div>
      <!--end: Form Wizard Nav -->
    </div>
    <div >
      <div>
        <form class="form px-5" id="kt_form">
          <!--begin: Form Wizard Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current" [formGroup]="steps[1]" *ngIf="!lead.exclusiveLead" >
  
            <div class="form-group mb-4">
              <label class="mb-4">
                {{'LEADS.COMPLAINT.ics' | translate}}:
              </label>

              <div *ngFor="let ic of lead.bought_for; let i=index">
                <label *ngIf="ic.refundable" class="pl-2">
                  <input type="checkbox" formArrayName="ics" class="mr-1" [value]="ic.id" (change)="onCheckboxChange($event)" />
                  <span class="d-inline-block overflow-hidden pl-2">{{ ic.short_name }}</span>
                </label>
              </div>

              <ng-container *ngIf="steps[1].controls['ics'].errors && steps[1].controls['ics'].touched">
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block text-danger">
                    {{ 'LEADS.COMPLAINT.COMPANY_REQUIRED' | translate }}
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
          <!--end: Form Wizard Step 1-->

          <!--begin: Form Wizard Step 2-->
          <div data-wizard-type="step-content" data-wizard-state="current" [formGroup]="steps[2]" >
            
            <div class="form-group">
              <label>
                {{'LEADS.COMPLAINT.reason' | translate}}:
              </label>

              <ng-select
                [items]="reasons" 
                [placeholder]="'LEADS.COMPLAINT.REASON_PLACEHOLDER' | translate"
                [clearable]="false" 
                [loading]="isLoading$ | async" 
                [disabled]="isLoading$ | async"
                formControlName="reason" 
                [class.is-invalid]="checkControl(false, 2,'reason')"
                [class.is-valid]="checkControl(true, 2,'reason')">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  {{'LEADS.COMPLAINT.OPTIONS.'+item | translate}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{'LEADS.COMPLAINT.OPTIONS.'+item | translate}}
                </ng-template>
              </ng-select>

              <span class="text-gray">
                {{'LEADS.COMPLAINT.INFO.REASON' | translate}}
              </span>
            </div>

            <div class="form-group">
              <label>
                {{'LEADS.COMPLAINT.desc' | translate}}:
              </label>

              <textarea
                class="form-control"
                formControlName="desc" 
                [class.is-invalid]="checkControl(false, 2,'desc')"
                [class.is-valid]="checkControl(true, 2,'desc')">
              </textarea>

              <span class="text-gray">
                {{'LEADS.COMPLAINT.INFO.DESC' | translate}}
              </span>
            </div>

          </div>
          <!--end: Form Wizard Step 2-->

          <!--begin: Form Wizard Step 3-->
          <div data-wizard-type="step-content" [formGroup]="steps[3]" >

            <div class="form-group">
              <h4 clss="mb-3">{{'LEADS.COMPLAINT.SUMMARY' | translate}}</h4>
              <ul>
                <li><strong>{{'LEADS.COMPLAINT.reason' | translate}}:</strong> {{'LEADS.COMPLAINT.OPTIONS.'+steps[2].controls['reason'].value | translate}}</li>
                <li><strong>{{'LEADS.COMPLAINT.desc' | translate}}:</strong> {{steps[2].controls['desc'].value}}</li>
                <li *ngIf="!lead.exclusiveLead"><strong>{{'LEADS.COMPLAINT.ics' | translate}}:</strong> {{selectedIcs()}}</li>
              </ul>
            </div>

            <div class="form-group">
              <h4 class="mb-3">{{'LEADS.COMPLAINT.agreement' | translate}}</h4>

              <label class="mb-0">
                <input type="checkbox" formControlName="agreement" name="agreement" class="agreement-input mr-2 hue"/>
                <span class="agreement-content">{{'LEADS.COMPLAINT.agreement_value' | translate}}</span>
              </label>

              <ng-container *ngIf="steps[3].controls['agreement'].errors && steps[3].controls['agreement'].touched">
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block text-danger">
                    {{ 'LEADS.COMPLAINT.AGREEMENT_REQUIRED' | translate }}
                  </div>
                </div>
              </ng-container>

              <p class="mt-3 text-gray">
                {{'LEADS.COMPLAINT.INFO.AGREEMENT' | translate}}
              </p>
            </div>

          </div>
          <!--end: Form Wizard Step 3-->

          <!--begin: Form Actions -->
          <div class="d-flex justify-content-between border-top py-5">
            <div class="mr-2">
              <div (click)="prevStep()" data-wizard-type="action-prev"
                class="btn btn-outline-primary font-weight-bold text-uppercase" >
              {{'GLOBAL.PREV_STEP' | translate}}
              </div>
            </div>
            <div>
              <div (click)="onSubmit();" data-wizard-type="action-submit"
                class="btn btn-primary font-weight-bold text-uppercase" >
              <div *ngIf="false then spinner else textBlock">
              </div>
              <ng-template #spinner>
                <span style="left:-5px" class="spinner spinner-secondary m-3"></span>
              </ng-template>
              <ng-template #textBlock>{{'LEADS.COMPLAINT.SUBMIT_COMPLAINT' | translate}}</ng-template>
              </div>

              <div (click)="nextStep()" data-wizard-type="action-next"
                class="btn btn-primary font-weight-bold text-uppercase" >
              {{'GLOBAL.NEXT_STEP' | translate}}
              </div>
            </div>
          </div>
          <!--end: Form Actions -->
        </form>
        <!--end: Form Wizard Form-->
      </div>
    </div>
  
  </div>

  <div class="card border-0" *ngIf="lead?.complaint?.id">
    <div class="form">

      <div class="form-group m-5 mb-10">
        <h4>{{'LEADS.COMPLAINT.SUMMARY' | translate}}</h4>
        <ul>
            <ng-container *ngFor="let prop of lead?.complaint?.petition | keyvalue">
            <li *ngIf="prop.key != 'ics' || !lead.exclusiveLead">
                <strong>
                <span>
                    {{ 'LEADS.COMPLAINT.'+prop.key | translate}}:
                </span>
                </strong>
                {{  'LEADS.COMPLAINT.OPTIONS.'+prop.value == ( 'LEADS.COMPLAINT.OPTIONS.'+prop.value | translate ) ? prop.value : ( 'LEADS.COMPLAINT.OPTIONS.'+prop.value | translate ) }}
            </li>
            </ng-container>
        </ul>

        <h4 class="pt-5">{{'LEADS.COMPLAINT.STATUS' | translate}}</h4>
        <strong class="text-danger">{{ ('LEADS.COMPLAINT.STATUSES.' + lead.complaint.status) | translate }}</strong>
        <h4 class="pt-5" *ngIf="lead.complaint.comment" >{{'LEADS.COMPLAINT.COMMENT' | translate}}</h4>
        <span class="text-primary">{{ lead.complaint.comment }}</span>
      </div>

    </div>
  </div>

  <!--end: Wizard -->
</div>
